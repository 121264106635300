/* eslint-disable eqeqeq */
import React from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import CustomImage from '../../../common/custom-image/CustomImage';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import discountCodeTypes from '../../../const-values/discountCodeTypes';
import './ModelCard.scss';

const ModelCard = ({ card, className, url }) => {
  const { i18n, t } = useTranslation();

  const renderCardLink = () => {
    return (
      <RouterLink
        to={!url ? merchentRouterLinks?.singleModel(card?.id) : url}
        className="p-title"
      >
        {card?.name}
      </RouterLink>
    );
  };

  const renderPriceActionBtns = () => {
    return (
      <div className="price-actions-wrap">
        <div className="p-price">
          <p className="price-label">{t('priceWithImages')} : </p>
          <p className="price-value">
            {Number(card?.priceWithImages)}
            <span className="currency-span">{t('currency.sar')}</span>
          </p>
        </div>
        <div className="p-price">
          <p className="price-label">{t('priceWithIntroductoryVideo')}</p>
          <p className="price-value">
            {Number(card?.priceWithIntroductoryVideo)}
            <span className="currency-span">{t('currency.sar')}</span>
          </p>
        </div>
        <div className="p-price">
          <p className="price-label">{t('priceWithServiceVideo')}</p>
          <p className="price-value">
            {Number(card?.priceWithServiceVideo)}
            <span className="currency-span">{t('currency.sar')}</span>
          </p>
        </div>
      </div>
    );
  };

  const renderDiscontBadge = () => {
    if (card?.hasCode == 1) {
      if (card?.codeType == discountCodeTypes?.fixedValue) {
        return (
          <div className="discount-badge">
            {card?.codeValue}
            {t('currency.sar')} {t('off')}
          </div>
        );
      } else if (card?.codeType == discountCodeTypes?.percentageValue) {
        return (
          <div className="discount-badge">
            {card?.codeValue}% {t('off')}
          </div>
        );
      }
    }
  };

  return (
    <div className={`model-card ${className || ''}`}>
      {renderDiscontBadge()}
      <div className="model-card-content">
        <RouterLink
          to={!url ? merchentRouterLinks?.singleModel(card?.id) : url}
          className="p-logo"
        >
          <CustomImage className="custom-img-wrap" src={card?.logo} />
        </RouterLink>

        <div className="card-data">
          <p className="cat-catFilter">
            {card?.cat} {card?.catFilter ? `- ${card?.catFilter}` : ''}
          </p>
          <div className="title-price-wrap">
            {renderCardLink()}

            <div className="store-name-location-wrap">
              {card?.store && <p className="store-name">{card.store}</p>}
              {card?.city && <p className="store-city"> - {card.city}</p>}
            </div>

            {renderPriceActionBtns()}
          </div>

          {card?.hasCode == 1 && card?.discountEndDate && (
            <div className="discount-countdown">
              <Countdown date={new Date(card.discountEndDate).getTime()} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModelCard;
