/* eslint-disable eqeqeq */
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomImage from '../../../../common/custom-image/CustomImage';
import productTypes from '../../../../const-values/productTypes';
import './RejectedProductCard.scss';

const RejectedProductCard = ({
  card,
  type,
  className,
  setFetchParentCount,
  url
}) => {
  const { t } = useTranslation();
  const sharedProductT = (key) => t(`productCard.${key}`);

  const renderCardLink = () => {
    return <div className="p-title">{card?.product_name}</div>;
  };

  return (
    <div className={`rejected-product-card ${className || ''}`}>
      <div className="product-card-content">
        <div className="p-logo">
          <CustomImage className="custom-img-wrap" src={card?.logo} />
        </div>
        <div className="card-data">
          {type != productTypes?.merchentProduct && (
            <p className="cat-catFilter">
              {card?.cat} {card?.catFilter ? `- ${card?.catFilter}` : ''}
            </p>
          )}
          <div className="title-price-wrap">
            {renderCardLink()}

            <div className="store-name-location-wrap">
              {<p className="store-name">{card.store_name}</p>}
            </div>
            <div className="rejection-wrapper">
              <p className="title-p">{sharedProductT('rejection_count')} : </p>
              <p className="value-p">{card.rejection_count}</p>
            </div>
            <div className="user-wrapper">
              <p className="title-p">{sharedProductT('employee')} : </p>
              <p className="value-p">{card.user_name}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectedProductCard;
