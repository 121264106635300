import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import accountTypes from '../../../const-values/accountTypes';
import {
  employeesPermissionsValues,
  storePermissionsValues
} from '../../../const-values/permissions';
import { AffiliateProvider } from '../../../contexts/affiliate-context/AffiliateContext';
// import { ChatProvider } from '../../../contexts/chat-context/ChatContext';
import { EmployeesProvider } from '../../../contexts/employees-context/EmployeesContext';
import { ProductStatisticsProvider } from '../../../contexts/product-statistics-context/ProductStatisticsContext';
import { ProductsProvider } from '../../../contexts/products-context/ProductsContext';
import { SingleProductProvider } from '../../../contexts/single-product-context/SingleProductContext';
import { SingleStoreProvider } from '../../../contexts/single-store-context/SingleStoreContext';
import { StoreLeftedCartProvider } from '../../../contexts/store-lefted-cart-context/StoreLeftedCartContext';
import { StoreOrdersProvider } from '../../../contexts/store-orders-context/StoreOrdersContext';
import { StoreStatisticsProvider } from '../../../contexts/store-statistics-context/StoreStatisticsContext';
import { StoresProvider } from '../../../contexts/stores-context/StoresContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import AffiliateStatisticsSharePdfPage from '../../../pages/merchent-pages/merchent-affilliate-page/AffiliateStatisticsSharePdfPage';
import MerchentAffiliatePage from '../../../pages/merchent-pages/merchent-affilliate-page/MerchentAffiliatePage';
// import MerchentChatCenterPage from '../../../pages/merchent-pages/merchent-chat-center-page/MerchentChatCenterPage';
import MerchentEmployeesPage from '../../../pages/merchent-pages/merchent-employees-page/MerchentEmployeesPage';
import MerchentOtherOrdersPage from '../../../pages/merchent-pages/merchent-other-page/MerchentOtherOrdersPage';
import MerchentOtherPage from '../../../pages/merchent-pages/merchent-other-page/MerchentOtherPage';
import MerchentProductStatisticsPage from '../../../pages/merchent-pages/merchent-product-statistics-page/MerchentProductStatisticsPage';
import MerchentStoreDetailspage from '../../../pages/merchent-pages/merchent-store-details-page/MerchentStoreDetailsPage';
import MerchentStoreStatisticsPage from '../../../pages/merchent-pages/merchent-store-statistics-page/MerchentStoreStatisticsPage';
// import MerchentDashboardPage from '../../../pages/merchent-pages/merchent-dashboard-page/MerchentDashboardPage';
import MerchentStoresPage from '../../../pages/merchent-pages/merchent-stores-page/MerchentStoresPage';
import MerchentWalletPage from '../../../pages/merchent-pages/merchent-wallet-page/MerchentWalletpage';
import NewProductPage from '../../../pages/merchent-pages/new-product-page/NewProductPage';
import NewStorePage from '../../../pages/merchent-pages/new-store-page/NewStorePage';
// import ProductDetailsPage from '../../../pages/merchent-pages/Product-details-page/ProductDetailsPage';
import ProductsPage from '../../../pages/merchent-pages/products-page/ProductsPage';
import LeftedCartSinglePage from '../../../pages/merchent-pages/store-lefted-cart-page/LeftedCartSinglePage';
import StoreLeftedCartPage from '../../../pages/merchent-pages/store-lefted-cart-page/StoreLeftedCartPage';
import StoreOrdersPage from '../../../pages/merchent-pages/store-orders-page/StoreOrdersPage';

import TutorialPage from '../../../pages/merchent-pages/tutorial-page/TutorialPage';
import MerchentSettingsForm from '../../../pages/user-profile-menu-pages/merchent-settings-page/MerchentSettingsForm';
import MerchentSettingsPage from '../../../pages/user-profile-menu-pages/merchent-settings-page/MerchentSettingsPage';
import SaloonSettingsForm from '../../../pages/user-profile-menu-pages/merchent-settings-page/SaloonSettingsForm';
import ChangePasswordPageContent from '../../../pages/user-profile-menu-pages/user-change-password-page/ChangePasswordPageContent';
import { checkAuthedEmployeeRoute } from '../../../utils/checkPermission';
// import UserChangePasswordPage from '../../../pages/user-profile-menu-pages/user-change-password-page/UserChangePasswordPage';
import { Route } from 'react-router-dom';
import { MerchantModelsProvider } from '../../../contexts/merchant-models-context/MerchantModelsContext';
import { ModelsOrdersProvider } from '../../../contexts/models-orders-context/ModelsOrdersContext';
import AllAffiliatePage from '../../../pages/merchent-pages/all-affiliates-page';
import MerchantMessagesPage from '../../../pages/merchent-pages/merchant-messages-page/MerchantMessagesPage';
import MerchantStatisticsPage from '../../../pages/merchent-pages/merchant-statistics-page';
import LessOrderedProductsPage from '../../../pages/merchent-pages/merchant-statistics-page/less-ordered-products-page';
import MostCanceledProductsPage from '../../../pages/merchent-pages/merchant-statistics-page/most-canceled-products-page';
import MostOrderedProductsPage from '../../../pages/merchent-pages/merchant-statistics-page/most-ordered-products-page';
import MostOrderedStoresPage from '../../../pages/merchent-pages/merchant-statistics-page/most-ordered-stores-page';
import MostRejectedProductsPage from '../../../pages/merchent-pages/merchant-statistics-page/most-rejected-products-page';
import MerchantModelsPage from '../../../pages/merchent-pages/merchent-models-page';
import SingleModelPage from '../../../pages/merchent-pages/merchent-models-page/SingleModelPage';
import ModelsOrdersPage from '../../../pages/merchent-pages/models-orders-page';
import RefundTermsPage from '../../../pages/refund-terms-page/RefundTermsPage';
import protectMe from '../../../utils/protectMe';
import generalRouterLinks from '../general-routes/gerneralRouterLinks';
import merchentRouterLinks from '../merchent-routes/merchentRouterLinks';
import routerLinks from '../routerLinks';

const MerchentRoutes = () => {
  const { user, loggedIn } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    editStores,
    deleteStores,
    addStores,
    reviewOrders,
    addEmployees,
    reviewStatistics
  } = storePermissionsValues(t);
  const { addProducts, editProducts, deleteProducts } =
    employeesPermissionsValues(t);
  // const checkAuthedEmployeeRoute = ({
  //   rolesArr,
  //   supposedRouteRole,
  //   authed
  // }) => {
  //   if (rolesArr?.length > 0) {
  //     // check permission
  //     if (
  //       rolesArr.includes(editStores) ||
  //       rolesArr.includes(deleteStores) ||
  //       rolesArr.includes(addStores) ||
  //       rolesArr.includes(reviewOrders) ||
  //       rolesArr.includes(addEmployees) ||
  //       rolesArr.includes(reviewStatistics) ||
  //       rolesArr.includes(addProducts) ||
  //       rolesArr.includes(editProducts) ||
  //       rolesArr.includes(deleteProducts)
  //     ) {
  //       // check if authed by default like view stores route
  //       if (authed) {
  //         return accountTypes()?.employee;
  //       } else if (rolesArr.includes(supposedRouteRole))
  //         return accountTypes()?.employee;
  //     }
  //     return null;
  //   }
  // };

  return [
    // protectMe(
    //   routerLinks?.merchentDashboard,
    //   <MerchentDashboardPage />,
    //   1,
    //   routerLinks?.signinPage,
    //   loggedIn,
    //   user
    // ),
    protectMe(
      routerLinks?.merchentStores,
      <StoresProvider>
        <EmployeesProvider>
          <MerchentStoresPage />
        </EmployeesProvider>
      </StoresProvider>,
      'merchent_stores_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent, accountTypes()?.employee]
    ),
    // new store route
    protectMe(
      routerLinks?.merchentNewStore,
      <SingleStoreProvider>
        <NewStorePage />
      </SingleStoreProvider>,
      'merchent_new_store_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRoles: [storePermissionsValues(t)?.addStores],
          authed: false
        })
      ]
    ),
    // edit store route
    protectMe(
      routerLinks?.merchentEditStore(),
      <SingleStoreProvider>
        <NewStorePage />
      </SingleStoreProvider>,
      'new_store_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRoles: [storePermissionsValues(t)?.editStores],
          authed: false
        })
      ]
    ),
    // store details route
    protectMe(
      merchentRouterLinks?.merchentStoreDetails(),
      <SingleStoreProvider>
        <MerchentStoreDetailspage />
      </SingleStoreProvider>,
      4121241244,
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent
        // checkAuthedEmployeeRoute({
        //   rolesArr:
        // user?.storeRoles?.length >= 0 && user?.employeeRolesArray?.length >= 0
        //   ? [...user?.storeRoles, ...user?.employeeRolesArray]
        //   : [],
        //   supposedRouteRole: null,
        //   authed: false
        // })
      ]
    ),
    // products
    protectMe(
      merchentRouterLinks?.storeProducts(),
      <ProductsProvider>
        <ProductsPage />
      </ProductsProvider>,
      'merchent-products-page-id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRoles: [editProducts],
          authed: false
        })
      ]
    ),
    // protectMe(
    //   merchentRouterLinks?.storeProductDetails(),
    //   <SingleProductProvider>
    //     <ProductDetailsPage />
    //   </SingleProductProvider>,
    //   'products_details_page_id',
    //   routerLinks?.signinPage,
    //   loggedIn,
    //   user
    // ),
    protectMe(
      merchentRouterLinks?.storeNewProduct(),
      <SingleProductProvider>
        <NewProductPage />
      </SingleProductProvider>,
      'store_new_product_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRoles: [addProducts],
          authed: false
        })
      ]
    ),
    protectMe(
      merchentRouterLinks?.storeEditProduct(),
      <SingleProductProvider>
        <NewProductPage />
      </SingleProductProvider>,
      'edit_service_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRoles: [editProducts],
          authed: false
        })
      ]
    ),
    //
    // orders
    protectMe(
      merchentRouterLinks?.storeOrders(),
      <StoreOrdersProvider>
        <StoreOrdersPage />
      </StoreOrdersProvider>,
      'store-orders-route-id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRoles: [storePermissionsValues(t)?.reviewOrders],
          authed: false
        })
      ]
    ),
    // chat
    // protectMe(
    //   merchentRouterLinks?.merchentChatCenterRoute,
    //   <ChatProvider>
    //     <MerchentChatCenterPage />
    //   </ChatProvider>,
    //   'merchent-chat-route-id',
    //   routerLinks?.signinPage,
    //   loggedIn,
    //   user,
    //   [
    //     accountTypes()?.merchent,
    //     checkAuthedEmployeeRoute({
    //       rolesArr:
    user?.storeRoles?.length >= 0 && user?.employeeRolesArray?.length >= 0
      ? [...user?.storeRoles, ...user?.employeeRolesArray]
      : [],
    //       supposedRouteRole: null,
    //       authed: false
    //     })
    //   ]
    // ),
    //
    // store statistics
    protectMe(
      merchentRouterLinks?.storeStatistics(),
      <StoreStatisticsProvider>
        <MerchentStoreStatisticsPage />
      </StoreStatisticsProvider>,
      'store-statistics-route-key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRoles: [storePermissionsValues(t)?.reviewStatistics],
          authed: false
        })
      ]
    ),
    // Lefted cart
    protectMe(
      merchentRouterLinks?.storeLeftedCartRoute(),
      <StoreLeftedCartProvider>
        <StoreLeftedCartPage />
      </StoreLeftedCartProvider>,
      'store-lefted-cart-route-id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.storeLeftedCartDetailsRoute(),
      <StoreLeftedCartProvider>
        <LeftedCartSinglePage />
      </StoreLeftedCartProvider>,
      'store-sinlge-lefted-cart-route-id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    // employees
    protectMe(
      merchentRouterLinks?.merchentEmployees,
      <EmployeesProvider>
        <StoresProvider>
          <MerchentEmployeesPage />
        </StoresProvider>
      </EmployeesProvider>,
      'merchent_employees_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    //
    // account settings
    protectMe(
      merchentRouterLinks?.merchentSettings,
      <MerchentSettingsPage />,
      'merchent_settings_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRoles: [],
          authed: true
        })
      ],
      true,
      [
        // nested routes
        protectMe(
          merchentRouterLinks?.merchentSettingsNestedAccount,
          <MerchentSettingsForm />,
          'settings-account-route_key',
          routerLinks?.signinPage,
          loggedIn,
          user,
          [
            // accountTypes()?.user,
            accountTypes()?.merchent,
            checkAuthedEmployeeRoute({
              rolesArr:
                user?.storeRoles?.length >= 0 &&
                user?.employeeRolesArray?.length >= 0
                  ? [...user?.storeRoles, ...user?.employeeRolesArray]
                  : [],
              supposedRouteRoles: [],
              authed: true
            })
          ],
          false
        ),
        protectMe(
          merchentRouterLinks?.nesetedSaloonSettings,
          <SaloonSettingsForm />,
          'merchent_saloon_settings_route',
          routerLinks?.signinPage,
          loggedIn,
          user,
          [
            // accountTypes()?.user,
            accountTypes()?.merchent
          ],
          false
        ),
        protectMe(
          merchentRouterLinks?.nestedChangePassword,
          <ChangePasswordPageContent />,
          'merchent_change_password_route',
          routerLinks?.signinPage,
          loggedIn,
          user,
          [
            // accountTypes()?.user,
            accountTypes()?.merchent,
            checkAuthedEmployeeRoute({
              rolesArr:
                user?.storeRoles?.length >= 0 &&
                user?.employeeRolesArray?.length >= 0
                  ? [...user?.storeRoles, ...user?.employeeRolesArray]
                  : [],
              supposedRouteRoles: [],
              authed: true
            })
          ],
          false
        )
      ]
    ),

    //
    // other //
    protectMe(
      merchentRouterLinks?.merchentOther,
      <MerchentOtherPage />,
      'merchent_other_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.merchentOtherOrders,
      <MerchentOtherOrdersPage />,
      'merchent_other_orders_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.merchentTutorial,
      <TutorialPage />,
      'merchent_tutorial_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent, accountTypes()?.employee]
    ),
    protectMe(
      merchentRouterLinks?.merchantMessages,
      <MerchantMessagesPage />,
      'merchent_messages_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent, accountTypes()?.employee]
    ),
    protectMe(
      merchentRouterLinks?.merchantModels,
      <MerchantModelsProvider>
        <MerchantModelsPage />
      </MerchantModelsProvider>,
      'merchent_models_page_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.singleModel(),
      <SingleModelPage />,
      'merchent_single_model_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.modelsOrders,
      <ModelsOrdersProvider>
        <ModelsOrdersPage />
      </ModelsOrdersProvider>,
      'model_orders_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.allMerchantAffiliates,
      <AffiliateProvider>
        <AllAffiliatePage />
      </AffiliateProvider>,
      'all_affiliates_page_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.merchentStatistics,
      <MerchantStatisticsPage />,
      'merchant-store-page-route-id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.mostOrderedProducts,
      <MostOrderedProductsPage />,
      'most-ordered-products-page-route-id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.lessOrderedProducts,
      <LessOrderedProductsPage />,
      'less-ordered-products-page-route-id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.mostRejectedProducts,
      <MostRejectedProductsPage />,
      'most-rejected-products-page-route-id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.mostCanceledProducts,
      <MostCanceledProductsPage />,
      'most-canceled-products-page-route-id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.mostOrderedStores,
      <MostOrderedStoresPage />,
      'most-ordered-stores-page-route-id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.merchentWallet,
      <MerchentWalletPage />,
      'merchent_store_wallet_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    //
    protectMe(
      merchentRouterLinks?.storeProductAffilliate(),
      <AffiliateProvider>
        <MerchentAffiliatePage />
      </AffiliateProvider>,
      'merchent_store_affiliate_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent]
    ),
    protectMe(
      merchentRouterLinks?.storeProductStatisticsAffilliatePdfRoute(),
      <AffiliateStatisticsSharePdfPage />,
      'merchent_store_affiliate_stats_pdf_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.merchent],
      false
    ),
    protectMe(
      merchentRouterLinks?.storeProductStatistics(),
      <ProductStatisticsProvider>
        <MerchentProductStatisticsPage />
      </ProductStatisticsProvider>,
      'merchent_store_stats_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.merchent,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRoles: [storePermissionsValues(t)?.reviewStatistics],
          authed: false
        })
      ]
    ),
    <Route
      exact
      path={generalRouterLinks?.merchantRefundTermsRoute}
      key={'general_refund_terms_route_key'}
      element={<RefundTermsPage />}
    />
  ];
};

export default MerchentRoutes;
