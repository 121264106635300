/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import CubeIcon from '../../../common/icons/CubeIcon';
import PlusIcon from '../../../common/icons/PlusIcon';
import RemoveUserIcon from '../../../common/icons/RemoveUserIcon';
import StoresIcon from '../../../common/icons/StoresIcon';
import UserSettingsIcon from '../../../common/icons/UserSettingsIcon';
import UsersIcon from '../../../common/icons/UsersIcon';
import VideoPlayIcon from '../../../common/icons/VideoPlayIcon';
import WalletIcon from '../../../common/icons/WalletIcon';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import SuccessCard from '../../../components/success-card/SuccessCard';
import accountTypes from '../../../const-values/accountTypes';
import appPages from '../../../const-values/appPages';
import UserContext from '../../../contexts/user-context/UserProvider';
import useSelectedPage from '../../../custom-hooks/useSelectedPage';
import CloseMerchentModal from './CloseMerchentModal';
import MerchentDashboardCard from './MerchentDashboardCard';
import './MerchentDashboardPage.scss';

const MerchentDashboardPage = () => {
  const { i18n, t } = useTranslation();
  const { user, setCurrentUser } = useContext(UserContext);
  const [closeAccountModalOpened, setCloseAccountModalOpened] =
    React.useState(false);
  const { merchentDashboard } = appPages(i18n, t, user);
  useSelectedPage({
    currentPage: merchentDashboard
  });

  const labelTransString = (key) => t(`merchentDashboard.${key}`);
  const employeeDashboardCards = [
    {
      id: 1,
      title: labelTransString('employeeStoresCenter'),
      to: merchentRouterLinks?.merchentStores,
      icon: <StoresIcon />
    },
    {
      id: 2,
      title: labelTransString('settings'),
      to: merchentRouterLinks?.merchentSettings,
      icon: <UserSettingsIcon />
    },
    {
      id: 7,
      title: labelTransString('videoTutorial'),
      to: merchentRouterLinks?.merchentTutorial,
      icon: <VideoPlayIcon />
    },
    {
      id: 10,
      title: labelTransString('userMessages'),
      to: merchentRouterLinks?.merchantMessages,
      icon: <CubeIcon />
    },
    {
      id: 8,
      title: labelTransString('closeAccount'),
      to: null,
      icon: <RemoveUserIcon />
    }
  ];
  const merchentDashboardCards = [
    {
      id: 1,
      title: labelTransString('stores'),
      to: merchentRouterLinks?.merchentStores,
      icon: <StoresIcon />
    },
    {
      id: 2,
      title: labelTransString('employees'),
      to: merchentRouterLinks?.merchentEmployees,
      icon: <UsersIcon />
    },
    {
      id: 3,
      title: labelTransString('other'),
      to: merchentRouterLinks?.merchentOther,
      icon: <CubeIcon />
    },
    {
      id: 4,
      title: labelTransString('newStore'),
      to: merchentRouterLinks?.merchentNewStore,
      icon: <PlusIcon />
    },
    {
      id: 5,
      title: labelTransString('settings'),
      to: merchentRouterLinks?.merchentSettings,
      icon: <UserSettingsIcon />
    },
    {
      id: 6,
      title: labelTransString('wallet'),
      to: merchentRouterLinks?.merchentWallet,
      icon: <WalletIcon />
    },
    {
      id: 7,
      title: labelTransString('videoTutorial'),
      to: merchentRouterLinks?.merchentTutorial,
      icon: <VideoPlayIcon />
    },
    {
      id: 10,
      title: labelTransString('userMessages'),
      to: merchentRouterLinks?.merchantMessages,
      icon: <CubeIcon />
    },
    {
      id: 11,
      title: labelTransString('models'),
      to: merchentRouterLinks?.merchantModels,
      icon: <CubeIcon />
    },
    {
      id: 12,
      title: labelTransString('models_orders'),
      to: merchentRouterLinks?.modelsOrders,
      icon: <CubeIcon />
    },
    {
      id: 13,
      title: labelTransString('affiliates'),
      to: merchentRouterLinks?.allMerchantAffiliates,
      icon: <UsersIcon />
    },
    {
      id: 13,
      title: labelTransString('statistics'),
      to: merchentRouterLinks?.merchentStatistics,
      icon: <CubeIcon />
    },
    {
      id: 8,
      title: labelTransString('closeAccount'),
      to: null,
      icon: <RemoveUserIcon />
    }
  ];
  console.log('LJK: ', merchentDashboardCards);

  const renderDashboardCards = () => {
    const arr =
      user?.accountType == accountTypes()?.merchent
        ? merchentDashboardCards
        : employeeDashboardCards;
    return (
      <section className={`dashboard-cards-wrapper`}>
        {arr?.length > 0 &&
          arr.map((card) => {
            return (
              <MerchentDashboardCard
                setCloseAccountModalOpened={setCloseAccountModalOpened}
                closeAccountModalOpened={closeAccountModalOpened}
                key={card?.id}
                {...card}
              />
            );
          })}
      </section>
    );
  };

  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  const sharedSuccessT = (key) => t(`successSignup.${key}`);

  return (
    <div className="merchent-dashboard-page shared-dashboard-page shared-custom-page">
      {user?.firstTime ? (
        <div
          className="custom-container"
          style={{
            minHeight: 'calc(100vh - 148px)',
            display: 'grid',
            alignItems: 'center'
          }}
        >
          <div className="success-card-container">
            <SuccessCard
              className="success-signup"
              mainTitle={sharedSuccessT('mainTitle')}
              subTitle={sharedSuccessT('subTitle')}
              btnText={
                i18n.language === 'ar' ? 'الصفحة الرئيسية' : 'Home screen'
              }
              // btnUrl={routerLinks?.homePage}
              onClick={() => {
                setCurrentUser({
                  ...user,
                  firstTime: false
                });
              }}
            />
            <p className="copyright-p">{t('copyRights')}</p>
          </div>
        </div>
      ) : (
        <>
          <CustomBreadcrumb
            arr={[
              {
                title: sharedTrans('home'),
                isLink: true,
                to: routerLinks?.merchentDashboard
              },
              {
                title: sharedTrans('dashboard'),
                isLink: false
              }
            ]}
          />

          <div className="custom-container">
            <div className="title-arrow-wrap">
              <p className="main-page-title">
                {t('merchentDashboard.dashboard')}
              </p>
            </div>
            {renderDashboardCards()}
          </div>

          {closeAccountModalOpened && (
            <CloseMerchentModal
              modalOpened={closeAccountModalOpened}
              setModalOpened={setCloseAccountModalOpened}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MerchentDashboardPage;
