/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import ViewAs from '../../../components/view-as/ViewAs';
import filterTypes from '../../../const-values/filterTypes';
import { viewAsArr } from '../../../const-values/viewAsOptions';
import MerchantModelsContext from '../../../contexts/merchant-models-context/MerchantModelsContext';
import './ModelsSelectedFilter.scss';

const ModelsSelectedFilter = ({ allFetchedCats, allFetchedFilterCats }) => {
  const { t } = useTranslation();
  const {
    setSelectedViewAsObj,
    allFetchedTableData,
    tablePagination,
    tableFilter
  } = useContext(MerchantModelsContext);
  const navigate = useNavigate();
  const { search } = useLocation();
  const values = queryString.parse(search);

  const [selectedCat, setSelectedCat] = useState(null);
  useEffect(() => {
    if (allFetchedCats?.length > 0 && values?.cat_id) {
      const foundObj = allFetchedCats.find((obj) => obj?.id == values.cat_id);
      if (foundObj) setSelectedCat(foundObj);
    } else setSelectedCat(null);
  }, [values, allFetchedCats]);

  const handleChangeSort = (v) => {
    navigate(
      `${merchentRouterLinks?.merchantModels}?cat_id=${
        values?.cat_id || ''
      }&catFilter_id=${values?.catFilter_id || ''}&discount=${
        values?.discount || ''
      }&page=${values?.page || 1}`
    );
  };

  const handleClickCheckBoxListFilter = (name, v) => {
    if (name === filterTypes?.catFilter_id) {
      navigate(
        `${merchentRouterLinks?.merchantModels}?cat_id=${
          values?.cat_id || ''
        }&catFilter_id=${values?.catFilter_id || ''}&page=1`
      );
    }
  };
  const handleClickRouteFilterList = (name, v) => {
    const handleEachKey = (key) => {
      if (name === filterTypes[key]) {
        if (values[key]) return '';
        else return v;
      } else return values[key] || '';
    };
    navigate(
      `${merchentRouterLinks?.merchantModels}?cat_id=${handleEachKey(
        'cat_id'
      )}&catFilter_id=${values?.catFilter_id || ''}&page=1`
    );
  };

  const renderSelectedArr = (name, label, valuesArr, toBeSearchedArr) => {
    if (valuesArr?.length > 0) {
      return (
        <div className="single-filter-list">
          {label && <div className="single-filter-list-label">{label} : </div>}
          <div className="values-list-wrapper">
            {valuesArr.map((v) => {
              const foundObj =
                toBeSearchedArr?.length > 0 &&
                toBeSearchedArr.find((obj) => obj?.id == v);
              if (foundObj) {
                return (
                  <span
                    key={v}
                    onClick={() => {
                      handleClickCheckBoxListFilter(name, v);
                    }}
                  >
                    {foundObj?.name}
                    <CloseOutlined
                      style={{
                        padding: '0 2px'
                      }}
                    />
                  </span>
                );
              }
              return null;
            })}
          </div>
        </div>
      );
    }
  };

  const renderSelectedRouteFilter = (name, label, value, toBeSearchedArr) => {
    if (value) {
      const foundObj =
        toBeSearchedArr?.length > 0 &&
        toBeSearchedArr.find((obj) => obj.id == value);
      return (
        <div className="single-filter-list">
          {label && <div className="single-filter-list-label">{label} : </div>}
          <div className="values-list-wrapper">
            {foundObj?.name ? (
              <span
                onClick={() => {
                  handleClickRouteFilterList(name, foundObj?.id);
                }}
              >
                {foundObj.name}
                <CloseOutlined
                  style={{
                    padding: '0 2px'
                  }}
                />
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      );
    }
  };

  const renderFilteredBy = () => {
    return (
      <div className="filters-lists-wrapper">
        {tableFilter?.cat_id?.value &&
          renderSelectedRouteFilter(
            filterTypes?.cat_id,
            tableFilter.cat_id?.label(t),
            tableFilter.cat_id?.value,
            allFetchedCats
          )}

        {tableFilter?.catFilter_id?.value?.length > 0 &&
          renderSelectedArr(
            filterTypes?.catFilter_id,
            tableFilter?.catFilter_id?.label(t),
            tableFilter?.catFilter_id?.value,
            allFetchedFilterCats
          )}
      </div>
    );
  };

  return (
    <div className="models-selected-filter-wrapper">
      <div className="filtered-by-selected-wrapper">
        <div className="selected-cat-filteredby-wrapper">
          {selectedCat?.name && (
            <div className="results-cat-name-wrapper">
              {tablePagination?.total > 0 && (
                <span>
                  {tablePagination.total} {t('totalResult.results')}
                </span>
              )}
              <h3 className="selected-cat-name bold-font">
                {selectedCat.name}
              </h3>
            </div>
          )}
        </div>

        {(tableFilter?.catFilter_id?.value?.length > 0 ||
          tableFilter?.cat_id?.value) && (
          <div className="filtered-by-wrapper">
            <div className="filtered-title bold-font">
              {t('singleProductPage.filteredBy')}
            </div>
            {renderFilteredBy()}
          </div>
        )}
      </div>
      {allFetchedTableData?.length > 0 && (
        <div className="view-as-sort-wrapper">
          <ViewAs
            defaultValue={viewAsArr(t)[0]}
            cb={(obj) => setSelectedViewAsObj(obj)}
          />
        </div>
      )}
    </div>
  );
};

export default ModelsSelectedFilter;
