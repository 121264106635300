import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import timeOfUseImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/time-of-use.png';
import usersImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/users-img.png';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import './styles.scss';

const StatisticsBox = ({ img, title, value, url, className }) => {
  return (
    <Link to={url} className={`statistics-box ${className ? className : ''}`}>
      <img src={img} alt={title} />
      <p className="title-p">{title}</p>
    </Link>
  );
};

const MerchantStatisticsPage = () => {
  const { t } = useTranslation();
  const sharedT = (key, options) => t(`merchant_statistics.${key}`, options);

  return (
    <div className="all-statistics-page">
      <div className="breadcrumb-title">
        <CustomBreadcrumb
          arr={[
            {
              title: t('main_app_bar_links.home'),
              isLink: true,
              to: routerLinks?.homePage
            },
            {
              title: t('merchentDashboard.statistics'),
              isLink: false
            }
          ]}
        />
      </div>

      <div className="custom-container">
        <div className="boxes-wrap">
          <StatisticsBox
            img={timeOfUseImg}
            title={sharedT('most_ordered_products')}
            className="s-box primary"
            url={merchentRouterLinks.mostOrderedProducts}
          />
          <StatisticsBox
            img={timeOfUseImg}
            title={sharedT('less_ordered_products')}
            className="s-box primary"
            url={merchentRouterLinks.lessOrderedProducts}
          />
          <StatisticsBox
            img={timeOfUseImg}
            title={sharedT('most_rejected_products')}
            className="s-box danger"
            url={merchentRouterLinks.mostRejectedProducts}
          />
          <StatisticsBox
            img={timeOfUseImg}
            title={sharedT('most_canceled_products')}
            className="s-box primary"
            url={merchentRouterLinks.mostCanceledProducts}
          />
          <StatisticsBox
            img={usersImg}
            title={sharedT('most_ordered_stores')}
            className="s-box primary"
            url={merchentRouterLinks.mostOrderedStores}
          />
        </div>
      </div>
    </div>
  );
};

export default MerchantStatisticsPage;
