/* eslint-disable eqeqeq */
import { Tag } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomImage from '../../../common/custom-image/CustomImage';
import RenderOrderStatus from '../../../common/render-order-status/RenderOrderStatus';
import paymentTypes, {
  paymentStatusValues,
  paymentTypesValues
} from '../../../const-values/paymentTypes';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import './UserOrderCard.scss';
// import chatImg from '../../../assets/imgs/icons/chat-img.svg';
// import productStatus from '../../../const-values/productStatus';
import { CloudDownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import getTabbyCheckoutUrlApi from '../../../apis/cart-apis/getTabbyCheckoutUrlApi';
import getTamaraCheckoutUrlApi from '../../../apis/cart-apis/getTamaraCheckoutUrlApi';

// import routerLinks from '../../../components/app/routerLinks';
// import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';

const UserOrderCard = ({ card }) => {
  const { t, i18n } = useTranslation();
  const { user } = useContext(UserContext);

  const renderPaidData = () => {
    const product = card?.product;
    return (
      <div className="paid-info-wrap">
        {product?.paidImages && product?.paidImages?.length > 0 && (
          <div className="paid-images-wrapper">
            <p className="wrapper-title">{t('modelOrdersPage.paid_images')}</p>
            {product?.paidImages && product?.paidImages?.length > 0 && (
              <div className="images-list">
                {product.paidImages.map((img) => (
                  <a href={img.image ?? ''} target="_blank" rel="noreferrer">
                    <CustomImage src={img.image} />
                    <CloudDownloadOutlined className="icon" />
                  </a>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="videos-links">
          {!!product?.paidIntroductoryVideo && (
            <a
              href={product.paidIntroductoryVideo ?? ''}
              target="_blank"
              rel="noreferrer"
              className="paid-video-link"
            >
              {t('modelOrdersPage.priceWithIntroductoryVideo')}
              <CloudDownloadOutlined className="icon" />
            </a>
          )}
          {!!product?.paidServiceVideo && (
            <a
              href={product.paidServiceVideo ?? ''}
              target="_blank"
              rel="noreferrer"
              className="paid-video-link"
            >
              {t('modelOrdersPage.priceWithServiceVideo')}
              <CloudDownloadOutlined className="icon" />
            </a>
          )}
        </div>
      </div>
    );
  };

  const renderPaymentType = () => {
    const foundType = paymentTypes(t).find(
      (obj) => obj.value == card?.paymentType
    );
    if (foundType)
      return (
        <div className="img-label-wrap">
          <img src={foundType.img} alt="payment" />
          {/* <span>{foundType.name}</span> */}
        </div>
      );
  };

  const customApiRequest = useCustomApiRequest();

  const [isLoadingTabby, setIsLoadingPayment] = useState(false);
  let isMounted = true;

  const handleTammaraCheckout = async () => {
    customApiRequest(
      getTamaraCheckoutUrlApi(
        {
          id: card?.id
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingPayment(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            if (data?.checkout_url) window.location.replace(data.checkout_url);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'Try again later'
            });
          }
        }
      },
      (error) => {
        setIsLoadingPayment(false);
      }
    );
  };

  const handleTabbyCheckout = async () => {
    customApiRequest(
      getTabbyCheckoutUrlApi(
        {
          id: card?.id
        },
        user?.token,
        i18n.language,
        true
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingPayment(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            if (data?.checkoutUrl) window.location.replace(data.checkoutUrl);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'Try again later'
            });
          }
        }
      },
      (error) => {
        setIsLoadingPayment(false);
      }
    );
  };

  const renderRepayBtn = () => {
    if (card?.paymentType == paymentTypesValues.onlinePayment)
      return (
        <a
          // target="_blank"
          href={`${
            process.env.REACT_APP_BASE_URL
          }/Payment/preparePayment?order_id=${card?.id || ''}&type=1`}
          className="repay-link"
        >
          {t('repay')}
        </a>
      );
    else if (card?.paymentType == paymentTypesValues.tamara)
      return (
        <button
          // target="_blank"
          onClick={handleTammaraCheckout}
          className="my-orders-link"
          disabled={isLoadingTabby}
        >
          {isLoadingTabby && <LoadingOutlined />} {t('repay')}
        </button>
      );
    else if (card?.paymentType == paymentTypesValues.tabby)
      return (
        <button
          // target="_blank"
          onClick={handleTabbyCheckout}
          className="repay-link"
          disabled={isLoadingTabby}
        >
          {isLoadingTabby && <LoadingOutlined />} {t('repay')}
        </button>
      );
  };

  return (
    <div className="user-order-card">
      <div className="card-header">
        <div className="nums-method-wrap">
          <div className="num">
            <div className="label">{t('ordersPage.orderNumber')}</div>
            <div className="value"># {card?.id}</div>
          </div>
          {!!(card?.paymentStatus === paymentStatusValues.not_paid) &&
            renderRepayBtn()}
        </div>

        <div className="dates-wrap">
          <div className="place-date-wrapper">
            <p className="label">{t('ordersPage.placedDate')}</p>
            <div className="date value">
              {dayjs(card?.created_at).format('MMM, D, YYYY')}
            </div>
          </div>
        </div>

        <div className="method">
          {/* <div className="label">{t('ordersPage.paymentType')}</div> */}
          <Tag
            color={
              card?.paymentStatus === paymentStatusValues.paid
                ? 'green-inverse'
                : 'red-inverse'
            }
          >
            {card?.paymentStatus === paymentStatusValues.paid
              ? t('paid')
              : t('not_paid')}
          </Tag>
          {renderPaymentType()}
        </div>

        {/* <div className="status-wrap">
          <RenderOrderStatus status={card?.status} />
        </div> */}
      </div>

      <div className="product-wrap">
        <div className="img-order-status-wrapper">
          <CustomImage
            className="p-logo"
            src={
              card?.product?.productImages?.length > 0
                ? card.product.productImages[0]?.image
                : card?.product?.logo
            }
          />
          <div className="status-wrap">
            <RenderOrderStatus status={card?.status} />
          </div>
        </div>
        <div className="p-data">
          <div className="main-data">
            <div className="p-name">{card?.product?.name}</div>
            <div className="cat-filter-cat">
              {card?.product?.cat} - {card?.product?.catFilter}
            </div>
            <div className="item-price">
              <div className="label">{t('modelOrdersPage.price')} :</div>
              <div className="value">
                <span>{Number(card.productPrice)}</span>
                <span>{t('currency.sar')}</span>
              </div>
            </div>
            <div className="item-price">
              <div className="label">{t('modelOrdersPage.paid_images')} :</div>
              {!!card?.product && (
                <div className="value">
                  <span>{Number(card.product.priceWithImages)}</span>
                  <span>{t('currency.sar')}</span>
                </div>
              )}
            </div>
            <div className="item-price">
              <div className="label">
                {t('modelOrdersPage.priceWithIntroductoryVideo')} :
              </div>
              {!!card?.product && (
                <div className="value">
                  <span>{Number(card.product.priceWithIntroductoryVideo)}</span>
                  <span>{t('currency.sar')}</span>
                </div>
              )}
            </div>
            <div className="item-price">
              <div className="label">
                {t('modelOrdersPage.priceWithServiceVideo')} :
              </div>
              {!!card?.product && (
                <div className="value">
                  <span>{Number(card.product.priceWithServiceVideo)}</span>
                  <span>{t('currency.sar')}</span>
                </div>
              )}
            </div>
          </div>

          <div className="side-data">
            <div className="side-data-list">
              <div className="discount-price">
                <p className="label">{t('modelOrdersPage.discountPrice')}</p>
                <div className="date value">
                  {Number(card?.discountPrice)}{' '}
                  <span style={{ fontSize: '80%' }}>{t('currency.sar')}</span>
                </div>
              </div>
              <div className="tax-price">
                <p className="label">{t('modelOrdersPage.taxPrice')}</p>
                <div className="date value">
                  {Number(card?.taxPrice)}{' '}
                  <span style={{ fontSize: '80%' }}>{t('currency.sar')}</span>
                </div>
              </div>
              <div className="total-data">
                <div className="label">{t('ordersPage.total')}</div>
                <div className="value">
                  {Number(card.totalPrice)} <span>{t('currency.sar')}</span>
                </div>
              </div>
              <div className="inform-date-wrapper">
                <p className="label">{t('modelOrdersPage.date')}</p>
                <div className="date value">{card?.created_at}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderPaidData()}
    </div>
  );
};

export default UserOrderCard;
