/* eslint-disable eqeqeq */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import CustomImage from '../../../../common/custom-image/CustomImage';
import generalRouterLinks from '../../../../components/app/general-routes/gerneralRouterLinks';
import productTypes from '../../../../const-values/productTypes';
import './CanceledProductCard.scss';

const CanceledProductCard = ({
  card,
  type,
  className,
  setFetchParentCount,
  url
}) => {
  const { t } = useTranslation();
  const sharedProductT = (key) => t(`productCard.${key}`);

  const renderCardLink = () => {
    if (type == productTypes?.merchentProduct) {
      return <div className="p-title">{card?.product_name}</div>;
    }
    return (
      <RouterLink
        to={
          !url ? generalRouterLinks?.generalSingleProductRoute(card?.id) : url
        }
        className="p-title"
      >
        {card?.name}
      </RouterLink>
    );
  };

  return (
    <div className={`canceled-product-card ${className || ''}`}>
      <div className="product-card-content">
        {type == productTypes?.merchentProduct ? (
          <div className="p-logo">
            <CustomImage className="custom-img-wrap" src={card?.logo} />
          </div>
        ) : (
          <RouterLink
            to={
              !url
                ? generalRouterLinks?.generalSingleProductRoute(card?.id)
                : url
            }
            className="p-logo"
          >
            <CustomImage className="custom-img-wrap" src={card?.logo} />
          </RouterLink>
        )}

        <div className="card-data">
          {type != productTypes?.merchentProduct && (
            <p className="cat-catFilter">
              {card?.cat} {card?.catFilter ? `- ${card?.catFilter}` : ''}
            </p>
          )}
          <div className="title-price-wrap">
            {renderCardLink()}

            <div className="store-name-location-wrap">
              {<p className="store-name">{card.store_name}</p>}
            </div>
            <div className="cancellation-wrapper">
              <p className="title-p">
                {sharedProductT('cancellation_count')} :{' '}
              </p>
              <p className="value-p">{card.cancellation_count}</p>
            </div>
            <div className="user-wrapper">
              <p className="title-p">{sharedProductT('client')} : </p>
              <p className="value-p">{card.user_name}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanceledProductCard;
