import axios from 'axios';

const getAllModelsApi = async (user, paramsFilter, langIsoCode) => {
  const headers = (user) =>
    !user
      ? {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          lang: langIsoCode
        }
      : {
          Authorization: 'Bearer ' + user.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          lang: langIsoCode
        };

  try {
    const url = `/Model/all?cat_id=${paramsFilter?.cat_id || ''}&catFilter_id=${
      paramsFilter?.catFilter_id || ''
    }&page=${paramsFilter?.page || 1}`;
    const res = await axios.get(url, {
      headers: headers(user)
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllModelsApi;
