/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import getSingleModelApi from '../../../apis/models-apis/getSingleModelApi';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import SingleModelData from './SingleModelData';
import './SingleModelPage.scss';

const SingleModelPage = () => {
  const { i18n, t } = useTranslation();
  const params = useParams();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedSingleModel, setFetchedSingleModel] = useState(null);
  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    customApiRequest(
      getSingleModelApi(
        user?.token,
        {
          model_id: params?.model_id
        },
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            setFetchedSingleModel(data);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [pathname, i18n.language]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 332
        }}
      >
        <LoadingOutlined style={{ fontSize: 20 }} />
      </div>
    );
  } else if (fetchedSingleModel)
    return (
      <>
        <Helmet>
          <title>{fetchedSingleModel?.name}</title>
        </Helmet>

        <div className="single-model-page">
          <div className="breadcrumb-title">
            <CustomBreadcrumb
              arr={[
                {
                  title: t('main_app_bar_links.home'),
                  isLink: true,
                  to: routerLinks?.merchentDashboard
                },
                {
                  title: t('merchentDashboard.models'),
                  isLink: true,
                  to: merchentRouterLinks.merchantModels
                }
              ]}
            />
          </div>

          <div className="custom-container">
            <SingleModelData
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              fetchedSingleModel={fetchedSingleModel}
            />
          </div>
        </div>
      </>
    );
  return null;
};

export default SingleModelPage;
