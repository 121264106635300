/* eslint-disable eqeqeq */
import { Rate } from 'antd';
import React, { useContext, useState } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import addProductToFavApi from '../../../apis/products-apis/addProductToFavApi';
import hideOrPublishProductApi from '../../../apis/products-apis/hideOrPublishProductApi';
import CustomImage from '../../../common/custom-image/CustomImage';
import HeartIcon from '../../../common/icons/HeartIcon';
import HeartIconSolid from '../../../common/icons/HeartIconSolid';
import AffiliateIcon from '../../../common/icons/product-card-icons/AffiliateIcon';
import DeleteProductIcon from '../../../common/icons/product-card-icons/DeleteProductIcon';
import EditProductIcon from '../../../common/icons/product-card-icons/EditProductIcon';
import HideProductIcon from '../../../common/icons/product-card-icons/HideProductIcon';
import ShareProductIcon from '../../../common/icons/product-card-icons/ShareProductIcon';
import ShieldIcon from '../../../common/icons/ShieldIcon';
import StatisticsIcon from '../../../common/icons/store-card-icons/StatisctsIcon';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import RenderProductStatus from '../../../common/render-product-status/RenderProductStatus';
import generalRouterLinks from '../../../components/app/general-routes/gerneralRouterLinks';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import CustomShareBtn from '../../../components/custom-share-btn/CustomShareBtn';
import accountTypes from '../../../const-values/accountTypes';
import discountCodeTypes from '../../../const-values/discountCodeTypes';
import {
  employeesPermissionsValues,
  storePermissionsValues
} from '../../../const-values/permissions';
import productStatus from '../../../const-values/productStatus';
import productTypes from '../../../const-values/productTypes';
import refundOptions from '../../../const-values/refundOptions';
import GeneralProductsContext from '../../../contexts/general-products-context/GeneralProductsContext';
import ProductsContext from '../../../contexts/products-context/ProductsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkPermission from '../../../utils/checkPermission';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import './ProductCard.scss';
import SingleProductDeleteModal from './SingleProductDeleteModal';

const ProductCard = ({ card, type, className, setFetchParentCount, url }) => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    setRejectedModalOpened,
    setSelectedTableRow,
    isTogglingHide,
    setIsTogglingHide,
    setFetchTableDataCount,
    deleteModalOpened,
    setDeleteModalOpened,
    isDeletingProduct,
    setIsDeletingProduct
  } = useContext(ProductsContext);
  const { setLoadingFav, loadingFav } = useContext(GeneralProductsContext);
  const [selectedCard, setSelectedCard] = useState(null);

  const sharedProductT = (key) => t(`productCard.${key}`);

  const renderCardLink = () => {
    if (type == productTypes?.merchentProduct) {
      return <div className="p-title">{card?.name}</div>;
    }
    return (
      <RouterLink
        to={
          !url ? generalRouterLinks?.generalSingleProductRoute(card?.id) : url
        }
        className="p-title"
      >
        {card?.name}
      </RouterLink>
    );
  };

  const customApiRequest = useCustomApiRequest();

  const handleFav = () => {
    setLoadingFav(true);
    const formData = new FormData();
    formData.append('product_id', card?.id);
    customApiRequest(
      addProductToFavApi(formData, user?.token, i18n.language),

      (res) => {
        setLoadingFav(false);
        setSelectedCard(null);
        if (checkRes(res)) {
          if (setFetchParentCount) {
            setFetchParentCount((prev) => prev + 1);
          }
        }
      },
      (error) => {
        setLoadingFav(false);
        setSelectedCard(null);
      }
    );
  };

  const renderPriceActionBtns = () => {
    if (type == productTypes?.merchentProduct)
      return (
        <div className="p-price">
          {card?.price} <span className="currency-label"> SAR</span>
        </div>
      );
    return (
      <div className="price-actions-wrap">
        <div className="p-price">
          {card?.hasCode == 1 ? (
            <div className="old-new-price-wrapper">
              <span className="old-price striked">
                {card?.price}
                <span className="line"></span>
              </span>
              <span className="new-price">
                {card.priceOffer}{' '}
                <span className="currency-span">{t('currency.sar')}</span>
              </span>
            </div>
          ) : (
            <span className="no-discount-price">
              {card?.price}
              <span className="currency-span">{t('currency.sar')}</span>
            </span>
          )}
        </div>

        <div className="actions-wrap">
          <button
            className={`action-btn-itself ${card?.is_fav ? 'is-fav' : ''}`}
            onClick={() => {
              setSelectedCard(card);
              handleFav();
            }}
          >
            {card?.is_fav ? <HeartIconSolid /> : <HeartIcon color="#C4C4C4" />}
          </button>
          {/* <button className="action-btn-itself">
            <ShareIcon color="#C4C4C4" />
          </button> */}
          <CustomShareBtn obj={card} />
        </div>
      </div>
    );
  };

  const renderRefundOption = () => {
    if (refundOptions(t)?.length > 0) {
      const foundRefundOption = refundOptions(t).find(
        (item) => item.id == card?.refundOption
      );
      if (foundRefundOption)
        return (
          <p
            className="refund-title"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 4
            }}
          >
            <ShieldIcon color="#25d366" />
            {foundRefundOption.title}
          </p>
        );
      return '';
    }
  };

  const handleToggleProductHidden = (status) => {
    const formData = new FormData();
    formData.append('product_id', card?.id);
    formData.append('status', status);
    customApiRequest(
      hideOrPublishProductApi(formData, user?.token, i18n.language),
      (res) => {
        setIsTogglingHide(true);
        setSelectedCard(null);
        if (checkRes(res)) {
          setFetchTableDataCount((prev) => prev + 1);
        } else {
          errorNotification({
            title: 'Error',
            message: res?.data.message || 'Try again later'
          });
        }
      },
      (error) => {
        setSelectedCard(null);
        errorNotification({
          title: 'Error',
          message: error?.response?.data.message || 'Try again later'
        });
      }
    );
  };
  const renderDiscontBadge = () => {
    if (card?.hasCode == 1) {
      if (card?.codeType == discountCodeTypes?.fixedValue) {
        return (
          <div className="discount-badge">
            {card?.codeValue}
            {t('currency.sar')} {t('off')}
          </div>
        );
      } else if (card?.codeType == discountCodeTypes?.percentageValue) {
        return (
          <div className="discount-badge">
            {card?.codeValue}% {t('off')}
          </div>
        );
      }
    }
  };

  return (
    <div className={`product-card ${className || ''}`}>
      {renderDiscontBadge()}
      <div className="product-card-content">
        {type == productTypes?.merchentProduct ? (
          <div className="p-logo">
            <CustomImage className="custom-img-wrap" src={card?.logo} />
          </div>
        ) : (
          <RouterLink
            to={
              !url
                ? generalRouterLinks?.generalSingleProductRoute(card?.id)
                : url
            }
            className="p-logo"
          >
            <CustomImage className="custom-img-wrap" src={card?.logo} />
          </RouterLink>
        )}

        <div className="card-data">
          {type == productTypes?.merchentProduct && (
            <RenderProductStatus
              obj={card}
              setRejectedModalOpened={setRejectedModalOpened}
              setSelectedTableRow={setSelectedTableRow}
            />
          )}

          {type != productTypes?.merchentProduct && (
            <p className="cat-catFilter">
              {card?.cat} {card?.catFilter ? `- ${card?.catFilter}` : ''}
            </p>
          )}
          <div className="title-price-wrap">
            {renderCardLink()}

            <div className="store-name-location-wrap">
              {card?.store && <p className="store-name">{card.store}</p>}
              {card?.city && <p className="store-city"> - {card.city}</p>}
            </div>
            <div className="stars-wrap">
              <div className="stars-itself">
                <Rate disabled allowHalf defaultValue={card?.rate} />
              </div>
              <div className="count-wrap">
                <span
                  style={{
                    color: '#290A44'
                  }}
                >
                  {card?.rate}
                </span>

                <span
                  style={{
                    fontSize: '14px'
                  }}
                >
                  {i18n.language === 'ar' ? ' من ' : ' Of '} 5
                </span>
              </div>
            </div>

            {renderPriceActionBtns()}
          </div>

          {type == productTypes?.merchentProduct ? (
            <div className="card-actions-wrap">
              {user?.accountType == accountTypes()?.merchent && (
                <button
                  disabled={
                    card?.status == productStatus?.underReview ||
                    card?.status == productStatus?.rejected
                  }
                  onClick={() => {
                    setSelectedCard(card);
                    handleToggleProductHidden(
                      card?.status == productStatus?.active ||
                        card?.status == productStatus?.underReview
                        ? productStatus?.hidden
                        : productStatus?.active
                    );
                  }}
                  className="shared-action-btn hide-btn"
                >
                  <HideProductIcon />
                  <span>
                    {(card?.status == productStatus?.active ||
                      card?.status == productStatus?.underReview) &&
                      sharedProductT('hide')}
                    {(card?.status == productStatus?.hidden ||
                      card?.status == productStatus?.rejected) &&
                      sharedProductT('activate')}
                  </span>
                </button>
              )}

              {checkPermission({
                user,
                supposedRouteRoles: [
                  employeesPermissionsValues(t)?.editProducts
                ],
                authed: false
              }) && (
                <RouterLink
                  to={merchentRouterLinks?.storeEditProduct(
                    card?.store_id,
                    card?.id
                  )}
                  className="shared-action-btn edit-btn"
                >
                  <EditProductIcon />
                  <span>{sharedProductT('edit')}</span>
                </RouterLink>
              )}

              {card?.status == productStatus?.underReview ||
              card?.status == productStatus?.rejected ? (
                <button className="shared-action-btn statistics-btn">
                  <StatisticsIcon color="#6D6D6D" />
                  <span>{sharedProductT('statistics')}</span>
                </button>
              ) : (
                checkPermission({
                  user,
                  supposedRouteRoles: [
                    storePermissionsValues(t)?.reviewStatistics
                  ],
                  authed: false
                }) && (
                  <RouterLink
                    to={merchentRouterLinks?.storeProductStatistics(
                      card?.store_id,
                      card?.id
                    )}
                    className="shared-action-btn statistics-btn"
                  >
                    <StatisticsIcon color="#6D6D6D" />
                    <span>{sharedProductT('statistics')}</span>
                  </RouterLink>
                )
              )}

              {user?.accountType == accountTypes()?.merchent &&
                (card?.status == productStatus?.active ? (
                  <RouterLink
                    to={merchentRouterLinks?.storeProductAffilliate(
                      card?.store_id,
                      card?.id
                    )}
                    className="shared-action-btn affiliate-btn"
                  >
                    <AffiliateIcon />
                    <span>{sharedProductT('affiliate')}</span>
                  </RouterLink>
                ) : (
                  <button className="shared-action-btn affiliate-btn">
                    <AffiliateIcon />
                    <span>{sharedProductT('affiliate')}</span>
                  </button>
                ))}

              {user?.accountType == accountTypes()?.merchent && (
                <CustomShareBtn
                  disabled={
                    card?.status != productStatus?.active ||
                    card?.status == productStatus?.rejected
                  }
                  icon={<ShareProductIcon />}
                  // className="shared-action-btn share-btn"
                  obj={card}
                >
                  <span>{sharedProductT('share')}</span>
                </CustomShareBtn>
              )}

              {checkPermission({
                user,
                supposedRouteRoles: [
                  employeesPermissionsValues(t)?.deleteProducts
                ],
                authed: false
              }) && (
                <button
                  className="delete-btn"
                  onClick={() => {
                    setSelectedCard(card);
                    setDeleteModalOpened(true);
                  }}
                >
                  <DeleteProductIcon color="#6D6D6D" />
                  <span>{sharedProductT('delete')}</span>
                </button>
              )}
            </div>
          ) : (
            renderRefundOption()
          )}

          {card?.hasCode == 1 && card?.codeEndDate && (
            <div className="discount-countdown">
              <Countdown date={new Date(card.codeEndDate).getTime()} />
            </div>
          )}
        </div>
      </div>
      {loadingFav && selectedCard?.id === card?.id && <LoadingModal />}
      {isTogglingHide && selectedCard?.id === card?.id && <LoadingModal />}
      {deleteModalOpened && selectedCard?.id === card?.id && (
        <SingleProductDeleteModal
          product={card}
          setSelectedCard={setSelectedCard}
          modalOpened={deleteModalOpened}
          setModalOpened={setDeleteModalOpened}
          setFetchCount={setFetchTableDataCount}
          isSubmittingForm={isDeletingProduct}
          setIsSubmittingForm={setIsDeletingProduct}
        />
      )}
      {isDeletingProduct && selectedCard?.id === card?.id && <LoadingModal />}
    </div>
  );
};

export default ProductCard;
