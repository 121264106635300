/* eslint-disable eqeqeq */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import CustomImage from '../../../../common/custom-image/CustomImage';
import merchentRouterLinks from '../../../../components/app/merchent-routes/merchentRouterLinks';
import './StoreCard.scss';

const StoreCard = ({ card, type, className, setFetchParentCount, url }) => {
  const { t } = useTranslation();
  const sharedProductT = (key) => t(`productCard.${key}`);

  const renderCardLink = () => {
    return (
      <RouterLink
        className="store-logo"
        to={merchentRouterLinks?.merchentStoreDetails(card?.store_id)}
      >
        {card?.name}
      </RouterLink>
    );
  };

  return (
    <div className={`most-ordered-store-card ${className || ''}`}>
      <div className="product-card-content">
        <RouterLink
          to={
            !url
              ? merchentRouterLinks?.merchentStoreDetails(card?.store_id)
              : url
          }
          className="p-logo"
        >
          <CustomImage className="custom-img-wrap" src={card?.logo} />
        </RouterLink>

        <div className="card-data">
          <div className="title-price-wrap">
            {renderCardLink()}

            <div className="store-name-location-wrap">
              {<p className="store-name">{card.store_name}</p>}
            </div>
            <div className="rejection-wrapper">
              <p className="title-p">
                {sharedProductT('total_products_ordered')} :{' '}
              </p>
              <p className="value-p">{card.total_products_ordered}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreCard;
