import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import filterTypes from '../../../const-values/filterTypes';

export const handleNavigateAll = (
  checkBoxChecked,
  name,
  arr,
  navigate,
  values
) => {
  if (name === filterTypes?.catFilter_id) {
    navigate(
      `${merchentRouterLinks.merchantModels}?cat_id=${
        values?.cat_id || ''
      }&catFilter_id=${
        checkBoxChecked && arr?.length > 0 ? arr.map((item) => item.id) : ''
      }&page=1`
    );
  }
};

export const handleNavigateSingleFilter = (
  name,
  checkboxFieldValuesArr,
  navigate,
  values
) => {
  if (name === filterTypes?.catFilter_id) {
    navigate(
      `${merchentRouterLinks.merchantModels}?cat_id=${
        values?.cat_id || ''
      }&catFilter_id=${
        checkboxFieldValuesArr?.length > 0 ? checkboxFieldValuesArr : ''
      }&page=1`
    );
  }
};
