const merchentRouterLinks = {
  // merchentDashboard: '/merchent-dashboard',
  merchentDashboard: '/',
  //
  merchentStores: '/merchent/stores',
  merchentNewStore: '/merchent/new-store',
  merchentEditStore: (store_id) => {
    if (store_id) return `/merchent/stores/${store_id}/edit`;
    return '/merchent/stores/:store_id/edit';
  },
  merchentStoreDetails: (store_id) => {
    if (store_id) return `/merchent/store/${store_id}`;
    return '/merchent/store/:store_id';
  },
  storeProducts: (store_id) => {
    if (store_id) {
      return `/merchent/store/${store_id}/products`;
    }
    return '/merchent/store/:store_id/products';
  },
  storeProductDetails: (store_id, product_id) => {
    if (store_id && product_id) {
      return `/merchent/store/${store_id}/products/${product_id}`;
    }
    return '/merchent/store/:store_id/products/:product_id';
  },
  storeNewProduct: (store_id) => {
    if (store_id) return `/merchent/store/${store_id}/new-product`;
    return '/merchent/store/:store_id/new-product';
  },
  storeEditProduct: (store_id, product_id) => {
    if (store_id && product_id)
      return `/merchent/store/${store_id}/edit-product/${product_id}`;
    return '/merchent/store/:store_id/edit-product/:product_id';
  },
  storeOrders: (store_id) => {
    if (store_id) return `/merchent/store/${store_id}/orders`;
    return '/merchent/store/:store_id/orders';
  },
  merchentChatCenterRoute: '/merchent/chat-center',
  storeStatistics: (store_id) => {
    if (store_id) return `/merchent/store/${store_id}/statistics`;
    return '/merchent/store/:store_id/statistics';
  },
  storeOrderDetails: (store_id, order_id) => {
    if (store_id && order_id)
      return `/merchent/store/${store_id}/orders/${order_id}`;
    return '/merchent/store/:store_id/orders/:order_id';
  },
  storeLeftedCartRoute: (store_id) => {
    if (store_id) return `/merchent/store/${store_id}/lefted-cart`;
    return '/merchent/store/:store_id/lefted-cart';
  },
  storeLeftedCartDetailsRoute: (store_id, cart_id) => {
    if (store_id && cart_id)
      return `/merchent/store/${store_id}/lefted-cart/${cart_id}`;
    return '/merchent/store/:store_id/lefted-cart/:cart_id';
  },
  storeProductStatistics: (store_id, product_id) => {
    if (store_id && product_id)
      return `/merchent/store/${store_id}/product-statistics/${product_id}`;
    return '/merchent/store/:store_id/product-statistics/:product_id';
  },
  storeProductAffilliate: (store_id, product_id) => {
    if (store_id && product_id) {
      return `/merchent/store/${store_id}/product/${product_id}/affilliate`;
    }
    return '/merchent/store/:store_id/product/:product_id/affilliate';
  },
  storeProductStatisticsAffilliatePdfRoute: (
    store_id,
    product_id,
    agent_id
  ) => {
    if (store_id && product_id) {
      return `/merchent/store/${store_id}/product/${product_id}/affilliate-statistics/${agent_id}`;
    }
    return '/merchent/store/:store_id/product/:product_id/affilliate-statistics/:agent_id';
  },
  storeProductShare: (store_id, product_id) => {
    if (store_id)
      return `/merchent/store/${store_id}/product-share/${product_id}`;
    return '/merchent/store/:store_id/product-share/:product_id';
  },
  //
  merchentEmployees: '/merchent/employees',
  merchentSettings: '/merchent/settings',
  merchentSettingsAccount: '/merchent/settings/account',
  merchentSettingsNestedAccount: 'account',
  saloonSettings: '/merchent/settings/saloon-settings',
  nesetedSaloonSettings: 'saloon-settings',
  changePassword: '/merchent/settings/change-password',
  nestedChangePassword: 'change-password',
  merchentWallet: '/merchent/wallet',
  merchentOther: '/merchent/other',
  merchentOtherOrders: '/merchent/other/orders',
  merchentTutorial: '/merchent/tutorial',
  merchantModels: '/merchent/models',
  singleModel: (model_id) => {
    if (model_id) return `/merchent/models/${model_id}`;
    return '/merchent/models/:model_id';
  },
  modelsOrders: '/merchent/models/orders',
  allMerchantAffiliates: '/merchent/all-affiliates',
  merchentStatistics: '/merchent/statistics',
  mostOrderedProducts: '/merchent/statistics/most-ordered-products',
  lessOrderedProducts: '/merchent/statistics/less-ordered-products',
  mostCanceledProducts: '/merchent/statistics/most-canceled-products',
  mostRejectedProducts: '/merchent/statistics/most-rejected-products',
  mostOrderedStores: '/merchent/statistics/most-ordered-stores',
  merchantMessages: '/merchant-messages'
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
};

export default merchentRouterLinks;
