/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import getTabbyCheckoutUrlApi from '../../../apis/cart-apis/getTabbyCheckoutUrlApi';
import makeModelOrderApi from '../../../apis/models-apis/makeModelOrderApi';
import madaImg from '../../../assets/imgs/icons/mada.svg';
import masterCardImg from '../../../assets/imgs/icons/master-card.svg';
import tabbyImg from '../../../assets/imgs/icons/tabby.png';
import visaImg from '../../../assets/imgs/icons/visa.svg';
import AntdCheckboxsGroup from '../../../common/antd-form-components/AntdCheckboxsGroup';
import OrdersIcon from '../../../common/icons/store-card-icons/OrdersIcon';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import modelPricesOptionsList from '../../../const-values/modelPricesOptions';
import { paymentTypesValues } from '../../../const-values/paymentTypes';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';

const SingleModelForm = ({ isLoading, fetchedSingleModel }) => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        priceOptions: Yup.array()
          .of(Yup.string().required())
          .min(1, t('select_price_option'))
      })
    ),
    mode: 'all',
    defaultValues: {
      priceOptions: []
    }
  });

  // console.log('watch :, ', watch());
  // console.log('errors : ', errors);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    if (paymentType) {
      const formData = new FormData();
      formData.append('modelId', fetchedSingleModel.id);
      formData.append('paymentType', paymentType);
      if (data?.priceOptions && data?.priceOptions?.length > 0) {
        for (let price of data.priceOptions)
          formData.append('priceOptions[]', price);
      }
      setIsSubmittingForm(true);
      // setIsLoadSingleProduct(true);
      customApiRequest(
        makeModelOrderApi(formData, user?.token, i18n.language),
        (res) => {
          setIsSubmittingForm(false);
          // setIsLoadSingleProduct(false);
          if (checkRes(res)) {
            // navigate(userRouterLinks?.userCartRoute);
            reset({
              priceOptions: []
            });
            if (res?.data && res?.data?.data) {
              const data = res.data.data;
              if (paymentType === paymentTypesValues.onlinePayment) {
                window.location.replace(
                  `${process.env.REACT_APP_BASE_URL}/Payment/preparePayment?order_id=${data.id}&type=1`
                );
              } else if (paymentType === paymentTypesValues.tabby) {
                customApiRequest(
                  getTabbyCheckoutUrlApi(
                    {
                      id: data.id
                    },
                    user?.token,
                    i18n.language,
                    true
                  ),
                  (res) => {
                    if (checkRes(res) && res.data?.data) {
                      const data = res.data.data;
                      if (data?.checkoutUrl)
                        window.location.replace(data.checkoutUrl);
                    } else {
                      errorNotification({
                        title: t('errorTitle'),
                        message: res?.data?.message || 'Try again later'
                      });
                    }
                  },
                  (error) => {
                    setIsSubmittingForm(false);
                  }
                );
              }
            }

            // successNotification({
            //   title: t('successTitle'),
            //   message:
            //     i18n.language === 'ar'
            //       ? 'تم طلب الموديل بنجاح'
            //       : 'Order done successfully!'
            // });
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          // setIsLoadSingleProduct(false);
          errorNotification({
            title: t('errorTitle'),
            message: 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      errorNotification({
        title: t('errorTitle'),
        message:
          i18n?.language === 'ar'
            ? 'من فضلك اختار طريقة الدفع'
            : 'Select payment type'
      });
    }
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="order-model-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <AntdCheckboxsGroup
            name="priceOptions"
            // label="label here"
            className="price-options-checkboxes"
            // onChange={() => setValue('model_id', '')}
            control={control}
            validateStatus={errors?.priceOptions ? 'error' : ''}
            errorMsg={errors?.priceOptions?.message}
            options={modelPricesOptionsList(t, fetchedSingleModel)}
          />

          <div className="model-payment-section">
            {/* <h3>{t('checkoutForm.pyament')}</h3> */}

            <div className="payment-btns-wrap">
              <button
                type="button"
                onClick={() => {
                  setPaymentType(paymentTypesValues?.onlinePayment);
                }}
                className={`payment-btn online-btn ${
                  paymentType == paymentTypesValues?.onlinePayment
                    ? 'selected'
                    : ''
                }`}
              >
                <ul className="images-list">
                  <li>
                    <img src={madaImg} alt="mada" />
                  </li>
                  <li>
                    <img src={masterCardImg} alt="master-card" />
                  </li>
                  <li>
                    <img src={visaImg} alt="visa" />
                  </li>
                </ul>
              </button>

              {/* <button
              className={`payment-btn tamara-btn ${
                paymentType == paymentTypesValues?.tamara ? 'selected' : ''
              }`}
              onClick={() => {
                setPaymentType(paymentTypesValues?.tamara);
              }}
              style={{
                backgroundImage: `url(${tamaraImg})`
              }}
            >
            </button> */}

              <button
                type="button"
                className={`payment-btn tabby-btn ${
                  paymentType == paymentTypesValues?.tabby ? 'selected' : ''
                }`}
                onClick={() => {
                  setPaymentType(paymentTypesValues?.tabby);
                }}
                style={{
                  backgroundImage: `url(${tabbyImg})`
                }}
              >
                {/* <img src={tamaraImg} alt="tamara" /> */}
              </button>
            </div>
          </div>

          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            // loading={isSubmittingForm}
            disabled={
              isSubmittingForm ||
              !fetchedSingleModel?.inStock ||
              fetchedSingleModel?.inStock == 0
            }
          >
            {!isSubmittingForm ? (
              <p>
                <OrdersIcon color="#fff" />
                <span>{t('order_model')} </span>
              </p>
            ) : (
              <p>
                <LoadingOutlined />
                <span>{t('order_model')}</span>
              </p>
            )}
          </Button>
        </div>
      </Form>

      {isSubmittingForm && <LoadingModal />}
    </>
  );
};

export default SingleModelForm;
