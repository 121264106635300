import axios from 'axios';

const getTabbyCheckoutUrlApi = async (
  paramsFilter,
  token,
  langIsoCode,
  isModel = false
) => {
  try {
    const url = !isModel
      ? `/Payment/tabby/preparePayment?order_id=${paramsFilter?.id ?? ''}`
      : `/Payment/tabby/preparePayment?order_id=${
          paramsFilter?.id ?? ''
        }&type=1`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        lang: langIsoCode
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getTabbyCheckoutUrlApi;
