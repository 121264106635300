/* eslint-disable react-hooks/exhaustive-deps */

import { FilterOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../../components/app/routerLinks';
import CatsContext from '../../../contexts/cats-context/CatsContext';
import FilterCatsContext from '../../../contexts/filter-cats/FilterCatsContext';
import ModelsFilter from './ModelsFilter';
import ModelsPageData from './ModelsPageData';
import ModelsSelectedFilter from './ModelsSelectedFilter';
import './styles.scss';

const MerchantModelsPage = () => {
  const { t } = useTranslation();
  // const { isLoadingTableData, loadingFav } = useContext(GeneralProductsContext);
  const { fetchedData: allFetchedCats } = useContext(CatsContext);
  const { fetchedData: allFetchedFilterCats } = useContext(FilterCatsContext);

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const renderFilterDrawer = () => {
    return (
      <div className="general-products-drawer-filter-wrapper">
        <button className="filter-btn" onClick={showDrawer}>
          <FilterOutlined /> {t('productsPage.filter')}
        </button>
        <Drawer
          className="general-products-drawer"
          title="Basic Drawer"
          placement="right"
          onClose={onClose}
          open={visible}
          width={'80%'}
        >
          <ModelsFilter
            allFetchedCats={allFetchedCats}
            allFetchedFilterCats={allFetchedFilterCats}
          />
        </Drawer>
      </div>
    );
  };

  return (
    <div className="merchant-models-page">
      <div className="breadcrumb-title">
        <CustomBreadcrumb
          arr={[
            {
              title: t('main_app_bar_links.home'),
              isLink: true,
              to: routerLinks?.homePage
            },
            {
              title: t('merchentDashboard.models'),
              isLink: false
            }
          ]}
        />
      </div>

      <div className="custom-container">
        <div className="page-content-wrap">
          {renderFilterDrawer()}

          <ModelsFilter
            allFetchedCats={allFetchedCats}
            allFetchedFilterCats={allFetchedFilterCats}
          />
          <div className="selected-filter-data-wrapper">
            <ModelsSelectedFilter
              allFetchedCats={allFetchedCats}
              allFetchedFilterCats={allFetchedFilterCats}
            />
            <ModelsPageData />
          </div>
        </div>
      </div>

      {/* {isLoadingTableData && <LoadingModal />} */}
      {/* {loadingFav && <LoadingModal />} */}
    </div>
  );
};

export default MerchantModelsPage;
