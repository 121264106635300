import axios from 'axios';

const getMostCanceledProductsApi = async (filterObj, token, langIsoCode) => {
  try {
    const res = await axios.get(
      `/Reports/mostCanceledProducts?page=${filterObj?.page || 1}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token,
          lang: langIsoCode
        }
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default getMostCanceledProductsApi;
