import axios from 'axios';

const getSingleModelApi = async (token, filterValues, langIsoCode) => {
  try {
    const url = `/Model/single?model_id=${filterValues?.model_id || ''}`;
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        lang: langIsoCode
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getSingleModelApi;
