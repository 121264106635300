/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination } from 'antd';
import queryString from 'query-string';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import getAllModelsApi from '../../../apis/models-apis/getAllModelsApi';
import noProductsImg from '../../../assets/imgs/icons/no-products-img.svg';
import EmptyCompoent from '../../../common/empty-compoent/EmptyComponent';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import generalRouterLinks from '../../../components/app/general-routes/gerneralRouterLinks';
import MerchantModelsContext from '../../../contexts/merchant-models-context/MerchantModelsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import ModelCard from './ModelCard';
import './ModelsPageData.scss';

const ModelsPageData = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const {
    // tableFilter,
    isLoadingTableData,
    setIsLoadingTableData,
    setAllFetchedTableData,
    fetchTableDataCount,
    setFetchTableDataCount,
    allFetchedTableData,
    tablePagination,
    setTablePagination,
    selectedViewAsObj
  } = useContext(MerchantModelsContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    setIsLoadingTableData(true);
    customApiRequest(
      getAllModelsApi(
        user,
        { ...values },
        // { ...tableFilter, ...values },
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingTableData(false);
          if (checkRes(res) && res.data?.data?.data) {
            const data = res.data.data.data;
            setAllFetchedTableData(data);
            if (res.data.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchTableDataCount, search]);

  const renderProductsList = () => {
    return (
      allFetchedTableData?.length > 0 &&
      allFetchedTableData.map((obj) => {
        return (
          <ModelCard
            className={selectedViewAsObj?.name || ''}
            setFetchParentCount={setFetchTableDataCount}
            key={obj?.id}
            card={obj}
          />
        );
      })
    );
  };

  const renderSharedMOdelsSection = () => {
    return (
      <section className="models-page-data">
        <div className={`models-wrap ${selectedViewAsObj?.name || ''}`}>
          {renderProductsList()}
        </div>

        {tablePagination?.total > 0 && (
          <div className="pagination-total-wrapper">
            <Pagination
              showSizeChanger={false}
              defaultCurrent={1}
              showQuickJumper
              locale={{
                jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                page: ''
              }}
              current={tablePagination.current_page}
              pageSize={tablePagination.per_page}
              total={tablePagination.total}
              // itemRender={itemRender}
              onChange={(page, pageSize) => {
                setFetchTableDataCount((prev) => prev + 1);
                navigate(
                  `${generalRouterLinks?.generalProductsRoute}?store_id=${
                    values?.store_id || ''
                  }&country_id=${values?.country_id || ''}&city_id=${
                    values?.city_id || ''
                  }&cat_id=${values?.cat_id || ''}&catFilter_id=${
                    values?.catFilter_id || ''
                  }&discount=${values?.discount || ''}&delivery=${
                    values?.delivery || ''
                  }&rate=${values?.rate || ''}&fromPrice=${
                    values?.fromPrice || ''
                  }&toPrice=${values?.toPrice || ''}&paymentType=${
                    values?.paymentType || ''
                  }&sort=${values?.sort || ''}&page=${page || 1}`
                );
              }}
              hideOnSinglePage={true}
            />

            <div className="total-wrapper">
              <span>{t('totalResult.about')}</span>
              <span>{tablePagination.total}</span>
              <span>{t('totalResult.results')}</span>
            </div>
          </div>
        )}
      </section>
    );
  };

  //
  //
  //

  if (isLoadingTableData) {
    return (
      <>
        {/* <LoadingOutlined /> */}
        <LoadingModal />
      </>
      // <div
      //   style={{
      //     padding: '132px 0',
      //     margin: '32px auto'
      //   }}
      // >
      //   <LoadingOutlined />
      // </div>
    );
  } else if (allFetchedTableData?.length === 0) {
    return <EmptyCompoent img={noProductsImg} p="No models found !!" />;
  } else if (allFetchedTableData?.length > 0)
    return renderSharedMOdelsSection();

  return null;
};

export default ModelsPageData;
