/* eslint-disable react-hooks/exhaustive-deps */
import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import getMostOrderedProductsApi from '../apis/auth/getMostOrderedProductsApi';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useMostOrderedProducts = () => {
  const { i18n } = useTranslation();
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [fetchTableDataCount, setFetchTableDataCount] = useState(0);
  const [allFetchedTableData, setAllFetchedTableData] = useState([]);
  const [tablePagination, setTablePagination] = useState(null);
  const customApiRequest = useCustomApiRequest();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { user } = useContext(UserContext);

  useEffect(() => {
    let isMounted = true;

    setIsLoadingTableData(true);
    customApiRequest(
      getMostOrderedProductsApi(
        {
          ...values
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingTableData(false);
          if (checkRes(res) && res?.data?.data) {
            setAllFetchedTableData(res.data.data);
            if (res?.data?.data?.services?.pagination) {
              setTablePagination(res.data.data.services.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchTableDataCount]);

  return {
    isLoadingTableData,
    setIsLoadingTableData,
    //
    fetchTableDataCount,
    setFetchTableDataCount,
    //
    allFetchedTableData,
    setAllFetchedTableData,
    tablePagination
  };
};

export default useMostOrderedProducts;
